import React, { useCallback, useEffect, useState } from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import { IMFileType } from '~/models/FileTypes';

import { IMServiceOrderFile } from '~/models/ServiceOrderFile';

import api from '~/services/api';
import CustomersDocuments from './Partials/CustomersDocuments';
import ExtraDocuments from './Partials/ExtraDocuments';
import ModalConclude from './Partials/ModalConclude';
import ProcessOpening from './Partials/ProcessOpening';
import SellersDocuments from './Partials/SellersDocuments';
import { Container, SendAnalysis } from './styles';

export interface IFiles extends IMFileType {
  files?: IMServiceOrderFile[];
}

const WorkDocuments: React.FC = () => {
  const { serviceOrder,participants } = useServiceOrder();
  const [showConclude, setShowConclude] = useState(false);
  const [lackFiles, setLackFiles] = useState(false)

  const handleOpenConclude = useCallback(() => {
    setShowConclude(true);
  }, []);


  const getLackFiles = useCallback(() => {
      const PARTICIPANTS: any = {}

        participants.map(p => {
          if(p?.type === 'spouse') {
            PARTICIPANTS['conjuge_id'] = p.id
          }else if(p?.type === 'participant') {              
            PARTICIPANTS['participante_id'] = p.id
          }
        });

        if(serviceOrder.status.id !== 3) return;
        
        api
            .get<IMFileType[]>(
              `builders/file-types/${serviceOrder.id}`,
              {
                params: {
                  grupo: 1,
                  ...PARTICIPANTS,
                },
              }
            )
            .then((response) => {
              response.data.forEach(d => {
                if(d.files && d.files.length <= 0) {
                  setLackFiles(true)
                }
              })
            });
  }, [lackFiles])

  useEffect(() => {
    getLackFiles()
  }, [])

  return (
    <Container>
      <CustomersDocuments />
      {serviceOrder.status_id >= 9 && (
        <>
          {serviceOrder.finalidade_id === 1 && <SellersDocuments />}
          <ProcessOpening />
        </>
      )}
      {serviceOrder.status_id > 6 && <ExtraDocuments />}

      <ModalConclude
        show={showConclude}
        onHide={() => setShowConclude(false)}
      />

      <SendAnalysis className='flex flex-row items-center gap-4'>
        <span data-showme={lackFiles} className='data-[showme=true]:flex hidden text-gray-400 cursor-pointer font-semibold'> Atualizar lista </span>
        
        <button
          type="button"
          disabled={lackFiles}
          className="sendAnalisys disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
          onClick={() => handleOpenConclude()}
        >
          <span data-showme={lackFiles} className='data-[showme=true]:hidden flex'> Enviar para análise </span>
          <span data-showme={lackFiles} className='data-[showme=true]:flex hidden'> Ainda faltam arquivos </span>
        </button>
      </SendAnalysis>
    </Container>
  );
};

export default WorkDocuments;
