import { AxiosResponse } from 'axios';
import { InputHTMLAttributes, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Icons
import bathroom from '~/assets/defaults/bathroom.png';
import bedroom from '~/assets/defaults/bedroom.png';
import closet from '~/assets/defaults/closets.png';
import garage from '~/assets/defaults/garage.png';
import gourmetArea from '~/assets/defaults/gourmet-area.png';
import homeOffice from '~/assets/defaults/home-office.png';
import kitchen from '~/assets/defaults/kitchen.png';
import laundryArea from '~/assets/defaults/laundry-area.png';
import livingRoom from '~/assets/defaults/living-room.png';
import pantry from '~/assets/defaults/pantry.png';
import swimmingPool from '~/assets/defaults/swimming-pool.png';
import washroom from '~/assets/defaults/washroom.png';
import largeArea from '~/assets/icons/large-area.svg';
import mediumArea from '~/assets/icons/medium-area.svg';
import noneArea from '~/assets/icons/none-area.svg';
import smallArea from '~/assets/icons/small-area.svg';

// Hooks
import { useBuildYourHouse } from '~/hooks/BuildYourHouse';

// Services
import api from '~/services/api';

// Utils
import { getAccumulatedTotal, getAmountStatement, getBudget } from '~/utils/serviceOrderCalculations';
import { generateSlug } from '~/utils/slug';

// Types
import { IAsset, IEnvironment, IEnvironmentResponse, IInitialData, IOption, IOptionsPerQuantity, IOs, IParameterResponse, IStep, IStepMessage, IStepPayload, IStepProps, IStepRecovery, TStepTargets } from './types';

// Partials
import { useAuthContext } from '~/hooks/contexts/Auth';
import { IMServiceOrder } from '~/models/ServiceOrder';
import { MockButton } from '../Mocks';
import { Partials } from './Partials';

export function Interior({
    generalID, 
    stepType, 
    showNavigation,
    isOS,
    terrainAmount,

    urlEnvinromentCallback,
    urlFinishCallback,
    urlParametersCallback,
    urlRequestCallback,
    urlOSCallback,

    onSelectedStep,
    onOptionsSelected,
    onCompletedStatus, 
    onBudgetCalculation
  }: IStepProps) {
  const [stepSelected, setStepSelected] = useState<IStep | null>(null)
  const [optionsSelected, setOptionsSelected] = useState<IOption[]>([])
  const [actualStepType, setActualStepType] = useState<TStepTargets>( stepType || 'quarto')
  const [budget, setBudget] = useState(0);
  const [perQuantitySelected, setPerQuantitySelected] = useState<string | null>(null);
  const [actualStepTypeIndex, setActualStepTypeIndex] = useState<number>(1)
  
  // Hooks
  const {user} = useAuthContext()
  const showOptionPerQuantityREF = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>
  const {actions,status} = useBuildYourHouse({
    urlEnvinromentCallback: urlEnvinromentCallback || '',
    urlFinishCallback:    urlFinishCallback || '',
    urlParametersCallback:     urlParametersCallback || '',
    urlRequestCallback:     urlRequestCallback || '',
    hasOS: isOS
  })

  // DATA FETCHS
  const [baseData, setBaseData] = useState<IInitialData | null>(null)
  const [responseEnvironment, setResponseEnvironment] = useState<IEnvironmentResponse[]>([])
  const [responseParameters, setResponseParameters] = useState<IParameterResponse | null>(null)
  const [responseServiceOrder, setResponseServiceOrder] = useState<IOs | null>(null)

  // AUX Variables
  const DEFAULT_BDI = useMemo(() => user?.construtech?.bdi as unknown as number || (responseServiceOrder as IMServiceOrder)?.orc_parametros?.margem || 30, [user?.construtech])
  const STEP_HAS_QUANTITY = (type: TStepTargets | null) => {
    switch(type){
      case 'quarto':
        return true;
      case 'banheiro':
        return true;
      case 'closet':
        return true;
      default:
        return false;
    }
  }
  const ENVIRONMENTS_DATA: IEnvironment = useMemo(() =>  {

    if(!responseEnvironment || responseEnvironment.length <= 0) return {} as IEnvironment

    const ENVIRONMENTS: IEnvironment = JSON.parse(sessionStorage.getItem('MCF_BUY@ALREADY_INTERIOR_ENVIRONMENTS') || "{}") as IEnvironment

    if(ENVIRONMENTS && Object.keys(ENVIRONMENTS).length <= 0) {
      let OPTION_GROUP_KEY = 1;
      responseEnvironment.forEach(environment => {
        const type = generateSlug(environment.ambiente_tamanho.ambiente.ambiente) as TStepTargets
  
        if(ENVIRONMENTS[type]) {
          OPTION_GROUP_KEY += 1
          console.log(`option_${OPTION_GROUP_KEY}`, `option_${OPTION_GROUP_KEY + 1}`)
          ENVIRONMENTS[type].options.push(
        {
              // @ts-ignore
              id: environment.ambiente_tamanho.tamanho_id || environment.ambiente_tamanho.id || '',
              step_type: type,
              selected: true,
              metragem: environment.metragem,
              group_key: `option_${OPTION_GROUP_KEY}`,
              price: environment.vlrFinal,
              stateValue: environment.vlrEstado,
              valueFinishing: environment.vlrAcabamento,
              valueProfit: environment.vlrLucro
            }
          )
        }else{
          OPTION_GROUP_KEY = 1

          ENVIRONMENTS[type] = {
              id: environment.id,
              hasQuantity: STEP_HAS_QUANTITY(type),
              options: [
                {
                  // @ts-ignore
                  id: environment.ambiente_tamanho.tamanho_id || environment.ambiente_tamanho.id || '',
                  step_type: type,
                  selected: true,
                  metragem: environment.metragem,
                  group_key: `option_1`,
                  price: environment.vlrFinal,
                  stateValue: environment.vlrEstado,
                  valueFinishing: environment.vlrAcabamento,
                  valueProfit: environment.vlrLucro
                }
              ],
              vlrEstado: environment.vlrEstado,
              vlrAcabamento: environment.vlrAcabamento,
              vlrLucro: environment.vlrLucro,
              vlrFinal: environment.vlrFinal,
              selected: true,
          };
        }
      })
    }

    sessionStorage.setItem('MCF_BUY@ALREADY_INTERIOR_ENVIRONMENTS', JSON.stringify(ENVIRONMENTS || {}))

    return ENVIRONMENTS;
  },[responseEnvironment, responseServiceOrder])
  const ASSETS: IAsset = {
    'grande': {
      icon: largeArea
    },
    'medio': {
      icon: mediumArea
    },
    'médio': {
      icon: mediumArea
    },
    'nenhum': {
      icon: noneArea
    },
    'pequeno': {
      icon: smallArea
    },
    'quarto': {
      icon: bedroom
    },
    'closet': {
      image: closet,
      icon: closet
    },    
    'cozinha': {
      icon: kitchen
    },
    'area-gourmet': {
      icon: gourmetArea
    },
    'lavabo': {
      icon: laundryArea
    },
    'despensa': {
      icon: pantry
    },
    'piscina': {
      icon: swimmingPool
    },
    'garagem': {
      icon: garage
    },
    'sala': {
      icon: livingRoom
    },
    'bedroom': {
      icon: '',
      image: bedroom
    },
    'bathroom': {
      icon: '',
      image: bathroom
    },    
    'garage': {
      icon: '',
      image: garage
    },
    'gourmet_area': {
      icon: '',
      image: gourmetArea
    },
    'kitchen': {
      icon: '',
      image: kitchen
    },
    'laundry': {
      icon: '',
      image: laundryArea
    },
    'room': {
      icon: '',
      image: livingRoom
    },
    'storeroom': {
      icon: '',
      image: pantry
    },
    'pool': {
      icon: '',
      image: swimmingPool
    },
    'toilet': {
      icon: '',
      image: washroom
    },
    'office': {
      icon: '',
      image: homeOffice
    },
  }
  const STEP_MESSAGES: IStepMessage = {
    ['quarto']: {
      quantity: 'Quantos quartos você deseja?',
      description: 'Selecione o tamanho do quarto'
    },
    ['banheiro']: {
      quantity: 'Quantos banheiros você deseja?',
      description: 'Selecione o tamanho do banheiro'
    },
    ['closet']: {
      quantity: 'Quantos closet você deseja?',
      description: 'Selecione o tamanho do closet'
    },
    ['cozinha']: {
      description: 'Selecione o tamanho da cozinha',
      quantity: ''
    },
    ['area-gourmet']: {
      description: 'Selecione o tamanho da área gourmet',
      quantity: ''
    },
    ['area-de-servico']: {
      description: 'Selecione o tamanho da área de serviço',
      quantity: ''
    },
    ['lavabo']: {
      description: 'Selecione o tamanho do lavabo',
      quantity: ''
    },
    ['despensa']: {
      description: 'Selecione o tamanho da despensa',
      quantity: ''
    },
    ['piscina']: {
      description: 'Selecione o tamanho da piscina',
      quantity: ''
    },
    ['garagem']: {
      description: 'Selecione o tamanho da garagem',
      quantity: ''
    },
    ['sala']: {
      description: 'Selecione o tamanho da sala',
      quantity: ''
    },
    ['escritorio']: {
      description: 'Selecione o tamanho do escritório',
      quantity: ''
    }
  }
  const ALREADY_ENVIRONTMENTS_CALCULETED = useMemo(() => Object.keys(ENVIRONMENTS_DATA).length > 0 && true,[ENVIRONMENTS_DATA])
  const STEPS_MOUNTED = useMemo(() => {

    if(!baseData || (isOS && !responseServiceOrder) || !responseParameters ) return []

    const step_in_mount: IStep[] = []

    console.warn('STEPS_MOUNTED was CALLED', new Date().toLocaleString('pt-BR'))        

    // lENGTH N -> O(n)
    baseData?.ambientes.forEach(environment => {
      const SLUG = generateSlug(environment.ambiente) as TStepTargets
      const QUANTITY_RECOVERY = ENVIRONMENTS_DATA[SLUG]?.options.length  || 1
      const QUANTITY = STEP_HAS_QUANTITY(SLUG) ? QUANTITY_RECOVERY : -1
      const STEP_ALREADY = getFromStorageStepSelected(SLUG)
      const STEP_ALREADY_OPTIONS = ENVIRONMENTS_DATA[SLUG]?.options

      if(!SLUG) return;

      console.table({SLUG,options: ENVIRONMENTS_DATA[SLUG]?.options})

      if(STEP_ALREADY && STEP_ALREADY.terrainAmount === terrainAmount) {
        step_in_mount.push(STEP_ALREADY)
      } else{
        // console.log(`STEP ${SLUG} HAS THAT AMOUNT PRICES`)
        // console.table(ENVIRONMENTS_DATA[SLUG])

        const stepBody: IStep = {
          id: ENVIRONMENTS_DATA[SLUG]?.id || environment.id,
          idd: environment.id,
          margem: environment.margem,
          metragem: 0,
          terrainAmount,
          vlrAcabamento: ENVIRONMENTS_DATA[SLUG]?.vlrAcabamento || 0,
          vlrEstado: ENVIRONMENTS_DATA[SLUG]?.vlrEstado || 0,
          vlrFinal: ENVIRONMENTS_DATA[SLUG]?.vlrFinal || 0,
          vlrLucro: ENVIRONMENTS_DATA[SLUG]?.vlrLucro || 0,
          name: environment.ambiente,
          opcional: environment.opcional,
          photo: ASSETS[environment.imagem.split('.')[0]]?.image || '',
          icon: environment.icon,
          // @ts-ignore
          options: [],
          optionsPerQuantity: null,
          alreadyOptionsPerQuantity: (STEP_HAS_QUANTITY(SLUG) && ENVIRONMENTS_DATA[SLUG]?.options.length > 0) && true,
          hasQuantity: STEP_HAS_QUANTITY(SLUG),
          quantity: QUANTITY,
          description: STEP_MESSAGES[SLUG]?.description || '',
          question: STEP_MESSAGES[SLUG]?.quantity || '',
          type: SLUG,
          unico: environment.unico
        }
        
        // MOUNT THE INITIAL OPTIONS
        baseData.ambientes_tamanhos.forEach(environmentSize => {
          // console.table(environment.ambiente)
          // console.table(responseParameters)
          // console.table(responseParameters?.[environment.ambiente]?.margem)
          // console.table(environmentSize.ambiente.margem)
          
          const MARGEM = responseParameters?.acabamento?.margem || 1
          const STATE_VALUE_BASE = isOS ? responseServiceOrder?.terreno?.estado?.valor : terrainAmount || 1

          // console.table({
          //   STATE_VALUE_BASE,
          //   metragem: environmentSize.metragem, 
          //   MARGEM, 
          //   DEFAULT_BDI,
          //   terrainAmount
          // })

          if(environmentSize.ambiente_id === environment.id) {

            const OPTION_ALREADY_SELECTED = STEP_ALREADY_OPTIONS?.some(d=> d.id === environmentSize.tamanho.id && d.metragem === environmentSize.metragem) || false
            const ALREADY_ONLY_ONE_OPTION = STEP_ALREADY_OPTIONS?.length === 1 && true
            const {stateValue, valueFinal: price,valueFinishing,valueProfit } = getAmountStatement(
              STATE_VALUE_BASE,
              environmentSize.ambiente.margem, 
              environmentSize.metragem, 
              MARGEM, 
              DEFAULT_BDI
            )

            const OPTION_AMOUNT_DATA = {
              stateValue: stateValue,
              price: price,
              valueFinishing: valueFinishing,
              valueProfit: valueProfit,
            }

            // console.log('THE OPTION WAS MOUNTED')
            // console.table(OPTION_AMOUNT_DATA)

            const optionBody = {
              id: environmentSize.id,
              icon:  ASSETS[environmentSize.tamanho.tamanho.toLocaleLowerCase() || 'nenhum']?.icon || '',
              label: environmentSize.tamanho.tamanho,
              step_type: generateSlug(environment.ambiente) as TStepTargets,
              selected: OPTION_ALREADY_SELECTED,
              metragem: environmentSize.metragem,
              stateValue: OPTION_AMOUNT_DATA.stateValue,
              price: OPTION_AMOUNT_DATA.price,
              valueFinishing: OPTION_AMOUNT_DATA.valueFinishing,
              valueProfit: OPTION_AMOUNT_DATA.valueProfit
            }

            // console.table(optionBody)

            stepBody.options.push(optionBody)
          }
        })        

        if(environment.opcional === 1){
          const optionBody = {
            id: 0,
            icon:  ASSETS['nenhum']?.icon || '',
            label: 'Nenhum',
            selected: false,
            step_type: generateSlug(environment.ambiente) as TStepTargets,
            metragem: 0,
            price: 0,
            stateValue: 0,
            valueFinishing: 0,
            valueProfit: 0
          }

          stepBody.options.push(optionBody)
        }
        
        step_in_mount.push(stepBody)
      }
    })

    console.log('STEPS DATASHEET WAS MOUNTED')
    console.table(step_in_mount)
    return step_in_mount
  },[baseData, responseParameters,responseServiceOrder,terrainAmount,ENVIRONMENTS_DATA])


  // SET ALL FETCHS BASE TO GET ALL NECESSARY DATA 
  useEffect(() => {
    try {
      Promise.all(
        [
          api.get<IInitialData>('queries/inicial_data'),
          api.get<IEnvironmentResponse[]>(urlEnvinromentCallback || ''),
          api.get<IParameterResponse>(urlParametersCallback || ''),          
        ]
      ).then((resolvers: AxiosResponse[] ) => {
          setBaseData(resolvers[0]?.data || null)
          setResponseEnvironment(resolvers[1]?.data || [])
          setResponseParameters(resolvers[2]?.data || null)
        }).catch(rejects => console.table(rejects))

        if(isOS) {
          api.get<IOs>(urlOSCallback || '').then(resolver => {
            setResponseServiceOrder(resolver.data || null)
          })
        }
    } catch (error) {
      console.table(error)
      return
    }
    
  },[])

  // SET A FETCH TO MOUNT THE BUDGET
  useEffect(
    () => {  
      if(!isOS)  {
        return;
      };

      api.get<IOs>(`builders/service-orders/${generalID}`).then(response => {
        setBudget(getBudget(response.data as IMServiceOrder));
      });
  }, [isOS])

  // SELECT A STEP BY 'STEPTYPE' PROP (SLUG FROM ROUTE PARAMS), OR LOCAL 'STEPTYPE' STATE IN actualStepType
  useEffect(() => {
    try {
      console.log(actualStepType)
      if (STEPS_MOUNTED.length <= 0 || !baseData ) return
  
      const STEP_ALREADY = getFromStorageStepSelected(actualStepType)

      let newStepSelected = STEP_ALREADY || STEPS_MOUNTED.find(
        (stepCheckData) => actualStepType && stepCheckData.type === actualStepType
      );
  
      if (!newStepSelected) {
        // FIXME - eslint-disable-next-line prefer-destructuring
        newStepSelected = STEPS_MOUNTED[0];
      }
      
      showOptionPerQuantityREF.current.checked = false
      
      if(newStepSelected.hasQuantity && newStepSelected.alreadyOptionsPerQuantity && !newStepSelected.optionsPerQuantity) {
        setMountOptionsPerQuantity(newStepSelected)
        return
      } else if(newStepSelected.hasQuantity && newStepSelected.options.length > 0 && !newStepSelected.optionsPerQuantity) {
        setMountOptionsPerQuantity(newStepSelected)
        return
      } else {
        setToStorageStepSelected(newStepSelected);
        
        handleSetDefaultOptionSelection(newStepSelected);

        setStepSelected({...newStepSelected});
      }

      onSelectedStep({...newStepSelected});
      onCompletedStatus(true)
    } catch (error) {
      console.table(error)
    }
  }, [
      actualStepType, baseData,
      responseParameters, responseServiceOrder
    ]
  );

  // UPDATE THE LOCAL STEP TYPE WITH BASE ON STEPTYPE FROM PROPS
  useEffect(() => {
    if(!stepType) return

    setActualStepType(stepType)
  },[stepType])

  // INFER THE INDEX POSITION FROM STEP TYPE BY STEPS MOUNTED
  useEffect(()=> {
    if(STEPS_MOUNTED.length <= 0 || !actualStepType) return

    const ACTUAL_STEP_TYPE_INDEX = STEPS_MOUNTED.findIndex(d => d.type === actualStepType )

    if(ACTUAL_STEP_TYPE_INDEX <= -1) return

    if((ACTUAL_STEP_TYPE_INDEX + 1) > STEPS_MOUNTED.length) {
      setActualStepTypeIndex(ACTUAL_STEP_TYPE_INDEX)
      // onSelectedStepPosition(ACTUAL_STEP_TYPE_INDEX)
    }else{
      setActualStepTypeIndex(ACTUAL_STEP_TYPE_INDEX + 1)
      // onSelectedStepPosition(ACTUAL_STEP_TYPE_INDEX + 1)
    }
  }, [actualStepType])

  // AUTO BUDGET CALCULATION
  useEffect(() => {
    if(!stepSelected) return;

    handleOnBudgetCalculation(ALREADY_ENVIRONTMENTS_CALCULETED);
  }, [stepSelected, budget])

  const setOptionSelected = useCallback(
    (optionChoosed: IOption | null) => {
      try {
        if(!stepSelected || !optionChoosed) return

        const newOptions = stepSelected.options.map((optionData) => {
          return {
            ...optionData,
            selected: optionData.id === optionChoosed.id && true,
          };
        });

        const newStep = { ...stepSelected };

        newStep.options = newOptions

        const newOptionsSeleted = optionsSelected.slice();
        const optionSelectedIndex = newOptionsSeleted.findIndex((optionData) => newStep.options.some(
          (optionCheck) => optionCheck.id === optionData.id
            && optionData.step_type === newStep.type
        ));
  
        if (optionSelectedIndex >= 0) {
          optionChoosed.selected = true
          newOptionsSeleted[optionSelectedIndex] = optionChoosed;
        } else {
          newOptionsSeleted.push(optionChoosed);
        }

        // console.table(optionChoosed)

        newStep.vlrEstado = optionChoosed.stateValue || 0;
        newStep.vlrAcabamento = optionChoosed.valueFinishing || 0;
        newStep.vlrFinal = optionChoosed.price || 0;
        newStep.vlrLucro = optionChoosed.valueProfit || 0;
  
        onCompletedStatus(true)
        onOptionsSelected(newOptionsSeleted)

        setToStorageStepSelected(newStep)        
        setOptionsSelected(newOptionsSeleted);
        setStepSelected(newStep);

        // console.warn('WILL CALL THE handleOnBudgetCalculation FUNCTION')

        // console.warn(`THE STEP ${newStep.name} have the vlrFinal: ${newStep.vlrFinal}`)

        handleOnBudgetCalculation(ALREADY_ENVIRONTMENTS_CALCULETED)
      } catch (error) {
        onCompletedStatus(false)
        setOptionsSelected([]);
      }
    },
    [optionsSelected, stepSelected]
  );

  function onMountOptionsPerQuantityData(quantity: number = -1, type: TStepTargets | null = null, options: IOption[] = []) {
    try {

      if(quantity <= -1 || !type || options.length <= 0) throw new Error('Some parameters is not valid')

      const CACHED_STEPS_SELECTED_INTERIOR = JSON.parse(sessionStorage.getItem('MCF_BUY@STEPS_SELECTED_INTERIOR') || "{}") as unknown as IStepRecovery
      const OPTIONS_PER_QUANTITY: IOptionsPerQuantity = {}
      const QUANTITY =   quantity || 1
      const TYPE =   type || ''      
      const SELECTED = (gk: string = '', metragem: number = -1) => {

        return !!(ENVIRONMENTS_DATA[TYPE]?.options.some(e => e.group_key === gk && e.metragem === metragem) 
          ||
          CACHED_STEPS_SELECTED_INTERIOR?.[TYPE]?.optionsPerQuantity?.[gk]?.options.find(e => e.metragem === metragem)?.selected
          )
          ||
          false;
      }
      const OPTIONS_RAW =  options || []

      Array(QUANTITY).fill(null).forEach((_q, index) => {
        const GROUP_KEY =  `option_${index + 1}`;
        const OPTIONS = ENVIRONMENTS_DATA[TYPE] ? 
          OPTIONS_RAW.map( d =>({...d, selected: SELECTED(GROUP_KEY, d.metragem)}))
          : 
          OPTIONS_RAW.map( d =>({...d, selected: SELECTED(GROUP_KEY, d.metragem)}))

        OPTIONS_PER_QUANTITY[GROUP_KEY] = {
          // THAT QUANTITY IS NOT NECESSARY, WILL BE DEPRECATED
          quantity: QUANTITY,
          options: OPTIONS
        }
      })

      return OPTIONS_PER_QUANTITY
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  /**
   * Update the amount of step selected by options per quantity
   * @param [step=null] The actual step that will receive the news options
   */
  function onUpdateStateAmountByOptions(step: IStep | null = null) {
    try {
      if(!step) throw new Error('The state should be selected')

      // DON'T HAVE ANY OPTION SELECTED, SO NO IS NECESSARY DO A FORCE RESET
      if(step.vlrEstado === 0 && step.vlrAcabamento === 0 && step.vlrFinal === 0 && step.vlrLucro === 0) 
        return step;

      step.vlrEstado = 0
      step.vlrAcabamento = 0
      step.vlrFinal = 0
      step.vlrLucro = 0

      Object.values(step.optionsPerQuantity || {}).forEach(optq => {
        optq.options.forEach(option => {
          if(option.selected === true) {
            step.vlrEstado += option.stateValue || 0;
            step.vlrAcabamento += option.valueFinishing || 0;
            step.vlrFinal += option.price || 0;
            step.vlrLucro += option.valueProfit || 0;
          }
        })
      })

      return step
    } catch (error) {
      throw error
    }
  }
  
  /**
   * MOUNT THE OPTIONS PER QUANTITY ON STEPSELECTED STATE
   * 
   * Will call the handleOnBudgetCalculation to calculation of budget
   * @param step 
   * @returns 
   */
  function setMountOptionsPerQuantity(step: IStep | null = null, alreadyOptions: boolean= false) {
    try {      
      if(!step || step.quantity <= 0) throw new Error('The step or step quantity data is missing');
  
      step.optionsPerQuantity = onMountOptionsPerQuantityData(step.quantity, step.type as TStepTargets, step.options);
  
      if(alreadyOptions === true) step = onUpdateStateAmountByOptions(step);
  
      setToStorageStepSelected(step);
  
      handleSetDefaultOptionSelection(step);
  
      setStepSelected({...step});
  
      handleOnBudgetCalculation(ALREADY_ENVIRONTMENTS_CALCULETED);
    } catch (error) {
      // do anything
    }
  }

  function handleSetDefaultOptionSelection(step: IStep | null = null){
    try {
      if(!step || step.quantity <= 0) throw new Error('The step or step quantity data is missing');

      if(step.options.length > 0 && step.optionsPerQuantity) {
        setPerQuantitySelected(`option_1`);

        handleSetResetInputStatues()
      }
    } catch (error) {
      // do anything
    }
  }

  /**
   * WILL RESET THE CHECK STATUS FROM A INPUT OPTION BY QUANTITY
   */
  function handleSetResetInputStatues(){
    try {
      const INPUTS_CHECKEDS = document.getElementsByName(`option-per-quantity-${stepSelected?.name}`)

      if(!INPUTS_CHECKEDS) throw new Error('The inputs is missing');

      Array.from(INPUTS_CHECKEDS).forEach((input: any) => {        
        if(input?.checked) {
          input.checked = false
        }
      })
    } catch (error) {
      console.table(error)
    }
  }

  function setToStorageStepSelected(stepTarget: IStep | null){
    try {
      if(!stepSelected) return

      let stepsSelectedRecovery = getAllFromStorageStepsSelected()

      if(stepsSelectedRecovery) {
        stepsSelectedRecovery[actualStepType] = stepTarget
      }else{
        stepsSelectedRecovery = {[actualStepType]: stepTarget}
      }

      sessionStorage.setItem("MCF_BUY@STEPS_SELECTED_INTERIOR", JSON.stringify(stepsSelectedRecovery))
    } catch (error) {
      // do anything
    }
  }

  function getFromStorageStepSelected(type: TStepTargets | null){
    try {
      return (JSON.parse(sessionStorage.getItem("MCF_BUY@STEPS_SELECTED_INTERIOR") || "{}") as any)[type || 'quarto'] as IStep | null
    } catch (error) {
      // do anything
    }
  }

  function getAllFromStorageStepsSelected(){
    try {
      return (JSON.parse(sessionStorage.getItem("MCF_BUY@STEPS_SELECTED_INTERIOR") || "{}") as any) as {[index: string]: IStep | null}
    } catch (error) {
      // do anything
    }
  }

  /**
   * Set a new quantity value by onChange event on input
   * @param event 
   */
  function setStepQuantity(event: any){
    try{
      if(!stepSelected || !event) throw new Error('Nenhum parametro é vávlido')

      if(!stepSelected.hasQuantity) throw new Error('Passo não é valido para quantidades')

      const target = event.target as InputHTMLAttributes<HTMLInputElement>

      if(!target || parseInt((target?.value as string) || '0',10) <= 0) throw new Error('Nenhum valor valido')

      const STEP_WITHOUT_REF = {...stepSelected}

      if(STEP_WITHOUT_REF.quantity <= 1) return

      STEP_WITHOUT_REF.quantity =  parseInt((target?.value as string) || '1',10)
      
      setMountOptionsPerQuantity(STEP_WITHOUT_REF, true)
    }catch(error: any){
      // do anything
    }
  }

  /**
   * Set a new quantity by onClick event on button
   * @returns 
   */
  function setStepQuantitySubstract(){
    try{
      if(!stepSelected) return
      
      const STEP_WITHOUT_REF = {...stepSelected}
      
      if(STEP_WITHOUT_REF.quantity <= 1) return
      
      STEP_WITHOUT_REF.quantity = (STEP_WITHOUT_REF.quantity - 1) <= 0 ? 1 : STEP_WITHOUT_REF.quantity - 1

      setPerQuantitySelected(`option_${STEP_WITHOUT_REF.quantity}`);

      setMountOptionsPerQuantity(STEP_WITHOUT_REF, true)
    }catch(error: any){
      // do anything
    }
  }

  /**
   * Set a new quantity by onClick event on button
   * @returns 
   */
  function setStepQuantityPlus() {
    try{
      if(!stepSelected) return

      const STEP_WITHOUT_REF = {...stepSelected}

      setPerQuantitySelected(`option_1`);
      
      STEP_WITHOUT_REF.quantity += 1

      setMountOptionsPerQuantity(STEP_WITHOUT_REF, true)
    }catch(error: any){
      // do anything
    }
  }

  /**
   * Set as selected a option from OPTIONS PER QUANTITY
   * @param optionChoosed 
   * @param groupKey 
   */
  function setSelectOptionByQuantity(optionChoosed: IOption | null, groupKey: string | null = null){
    try {
      
      console.table(optionChoosed)

      if(!optionChoosed) throw new Error('THE OPTION IS NOT VALID')

      if(!groupKey) throw new Error('THE GROUP KEY IS NOT VALID')

      if(!stepSelected) throw new Error('A STEP SHOULD BE SELECTED')
        
      const STEP_WITHOUT_REF = {...stepSelected}
        
      if(!STEP_WITHOUT_REF.optionsPerQuantity) throw new Error('THE STEP DO NOT HAVE OPTIONS PER QUANTITY')

      if(!STEP_WITHOUT_REF.optionsPerQuantity[groupKey]) throw new Error('THE GROUP KEY IS NOT VALID')

      // REMOVE THE AMOUNT FROM PREVILOUS OPTION SELECTED IF ACTUAL AMOUNT ARE THAN OF ZERO
      if(STEP_WITHOUT_REF.vlrEstado > 0 && STEP_WITHOUT_REF.vlrAcabamento > 0 && STEP_WITHOUT_REF.vlrFinal > 0 && STEP_WITHOUT_REF.vlrLucro > 0) {
        STEP_WITHOUT_REF.optionsPerQuantity[groupKey].options.forEach(option => {
          if(option.selected) {
              STEP_WITHOUT_REF.vlrEstado -= option.stateValue || 0;
              STEP_WITHOUT_REF.vlrAcabamento -= option.valueFinishing || 0;
              STEP_WITHOUT_REF.vlrFinal -= option.price || 0;
              STEP_WITHOUT_REF.vlrLucro -= option.valueProfit || 0;
            }
        })
      }

      STEP_WITHOUT_REF.optionsPerQuantity[groupKey].options = STEP_WITHOUT_REF.optionsPerQuantity[groupKey].options.map(d => {
        const SELECTED =  d.step_type === optionChoosed.step_type && d.id === optionChoosed.id && true

        return {
          ...d,
          selected: SELECTED
        }
      })
      
      console.warn(`THE STEP ${STEP_WITHOUT_REF.name} have the old vlrFinal: ${STEP_WITHOUT_REF.vlrFinal}`)

      STEP_WITHOUT_REF.vlrEstado += optionChoosed.stateValue || 0;
      STEP_WITHOUT_REF.vlrAcabamento += optionChoosed.valueFinishing || 0;
      STEP_WITHOUT_REF.vlrFinal += optionChoosed.price || 0;
      STEP_WITHOUT_REF.vlrLucro += optionChoosed.valueProfit || 0;

      console.warn(`THE STEP ${STEP_WITHOUT_REF.name} have the vlrFinal: ${STEP_WITHOUT_REF.vlrFinal} from ${optionChoosed.price}`)

      setToStorageStepSelected(STEP_WITHOUT_REF)

      setStepSelected({...STEP_WITHOUT_REF})

      handleOnBudgetCalculation(ALREADY_ENVIRONTMENTS_CALCULETED)
    } catch (error) {
      console.table(error)
    }
  }

  function handleOnBudgetCalculation(alreadyCalculeted: boolean = false){
    try {
      if(!stepSelected) throw new Error('Some step should be selected')
      if(!responseParameters) throw new Error('The main data is not available')
            
        
      /* -------------------------------------------------------------------------------------------------
      * CAN BE THAT THE USER DO NOT INTERACT WITH ALL STEPS AVAILABLES, SO ALL STEP DATA WILL BE RECOVERY FROM STEP DATA CREATE IN RUNTIME ON CREATION
      * OF THIS COMPONENT, AND FOR NOT LOST THE DATA THAT USER CHANGE THE CODE BELOW ARE EXECUTED
      * {...STEPS_MOUNTED_RECOVERY, ...STEPS_RECOVERY}
      * THE CODE ABOVE WILL MAINTEN THE NEW DATA AND DATA FROM USER
      */
      const STEPS_MOUNTED_RECOVERY_LIST: IStep[] = []
      const STEPS_MOUNTED_RECOVERY: IStepRecovery = {} as IStepRecovery
      
      STEPS_MOUNTED.forEach(d => {
        if(d.type) STEPS_MOUNTED_RECOVERY[d.type] = d
      })
      
      const STEPS_RECOVERY = (getAllFromStorageStepsSelected() || {}) as IStepRecovery
      const STEPS_SELECTED_RAW = Object.keys(STEPS_RECOVERY).length !== 12 ? {...STEPS_MOUNTED_RECOVERY, ...STEPS_RECOVERY} : STEPS_RECOVERY

      Object.keys(STEPS_SELECTED_RAW || {}).forEach(d => {
        if(STEPS_SELECTED_RAW && STEPS_SELECTED_RAW[d].type) 
          STEPS_MOUNTED_RECOVERY_LIST.push(STEPS_SELECTED_RAW[d] || {} as IStep );
      });         

      const total = alreadyCalculeted ? 
      (STEPS_MOUNTED_RECOVERY_LIST.reduce((a,b) => b.vlrFinal + a, 0))
      : 
      getAccumulatedTotal(responseParameters, STEPS_MOUNTED_RECOVERY_LIST);

      console.log({
        'handlerBudget': STEPS_MOUNTED_RECOVERY_LIST,
        'sum': STEPS_MOUNTED_RECOVERY_LIST.reduce((a,b) => {          
          return b.vlrFinal + a
        }, 0),
        total
      })

      const BUGTE_CALCULETED = {
        percent: budget > 0 ? Number(parseFloat(((total * 100) / budget).toFixed(2))) : 0,
        totalBudget: total,
        budget,
      }

      // console.table({
      //   total,
      //   STEPS_MOUNTED,
      //   responseParameters,
      //   STEPS_MOUNTED_RECOVERY_LIST
      // })

      console.table(STEPS_MOUNTED)
      console.table(BUGTE_CALCULETED)

      if(onBudgetCalculation && typeof onBudgetCalculation === 'function') 
        onBudgetCalculation(BUGTE_CALCULETED.percent,BUGTE_CALCULETED.totalBudget,BUGTE_CALCULETED.budget)

    } catch (error) {
      console.warn(error)
      console.table(error);
    }
  }

   /**
   * Handle to set a new selected options group and call the "finished function"
   */
  function handleOnFinished (){
    try {
      if(!stepSelected) throw new Error('Nenhum passo selecionado')

      /* -------------------------------------------------------------------------------------------------
       * CAN BE THAT THE USER DO NOT INTERACT WITH ALL STEPS AVAILABLES, SO ALL STEP DATA WILL BE RECOVERY FROM STEP DATA CREATE IN RUNTIME ON CREATION
       * OF THIS COMPONENT, AND FOR NOT LOST THE DATA THAT USER CHANGE THE CODE BELOW ARE EXECUTED
       * {...STEPS_MOUNTED_RECOVERY, ...STEPS_RECOVERY}
       * THE CODE ABOVE WILL MAINTEN THE NEW DATA AND DATA FROM USER
       */
      const STEPS_MOUNTED_RECOVERY: IStepRecovery = {} as IStepRecovery
      
      STEPS_MOUNTED.forEach(d => {
        if(d.type) STEPS_MOUNTED_RECOVERY[d.type] = d
      })
      
      const STEPS_RECOVERY = (getAllFromStorageStepsSelected() || {}) as IStepRecovery
      const STEPS_SELECTED_RAW = Object.keys(STEPS_RECOVERY).length !== 12 ? {...STEPS_MOUNTED_RECOVERY, ...STEPS_RECOVERY} : STEPS_RECOVERY
      const STEPS_SELECTED: IStepRecovery = {...STEPS_SELECTED_RAW}
      const STEPS_BY_PAYLOAD: IStepPayload = { ambientes: [] } 

      if(!STEPS_SELECTED_RAW) throw new Error('Nenhum "cache" encontrado');

      /* -----------------------------------------------------------------
       * THE CODE BELOW WILL CREATE ALL OPTION PER QUANTITY, IF SOME STEP DON'T HAVE
       * -----------------------------------------------------------------
       */
      Object.keys(STEPS_SELECTED_RAW).forEach(step => {
        if(STEPS_SELECTED_RAW[step]?.type) {
          const OPTIONS_PER_QUANTITY = STEPS_SELECTED_RAW[step].optionsPerQuantity || {} as IOptionsPerQuantity
          const SHOULD_OPTIONS_PER_QUANTITY = (STEPS_SELECTED_RAW[step].quantity >= 1 && STEPS_SELECTED_RAW[step].hasQuantity) && true

          if(SHOULD_OPTIONS_PER_QUANTITY && (!OPTIONS_PER_QUANTITY || Object.keys(OPTIONS_PER_QUANTITY).length <= 0) ) {

            STEPS_SELECTED[STEPS_SELECTED_RAW[step]?.type || ''] = {
              ...STEPS_SELECTED_RAW[step],
              optionsPerQuantity: onMountOptionsPerQuantityData(STEPS_SELECTED_RAW[step].quantity, STEPS_SELECTED_RAW[step].type as TStepTargets, STEPS_SELECTED_RAW[step].options)
            }
          }
        }
      })

      /* -----------------------------------------------------------------
       * END OF THE CODE BELOW WILL CREATE ALL OPTION PER QUANTITY, IF SOME STEP DON'T HAVE
       * -----------------------------------------------------------------
       */
      let GLOBAL_INDEX = 1

      Object.values(STEPS_SELECTED).forEach((step, index) => {
        if(step.hasQuantity && step.optionsPerQuantity && Object.values(step.optionsPerQuantity).length > 0) {
          // optPerQtd -> OPTION PER QUANTITY
          Object.values(step.optionsPerQuantity).forEach((optPerQtd) => {
            const OPTION_SELECTED = optPerQtd.options.find(d => d.selected == true)

            if(OPTION_SELECTED &&  OPTION_SELECTED.id > 0) {
              STEPS_BY_PAYLOAD.ambientes.push({
                id: step.id,
                ambiente_id: step.idd,
                amb_tam_id: OPTION_SELECTED.id,
                ambiente_tamanho: {
                  ambiente_id: step.idd
                },
                item: GLOBAL_INDEX,
                metragem: OPTION_SELECTED.metragem,
                selected: true,
                type: step.type as TStepTargets,
                vlrAcabamento: step.vlrAcabamento,
                vlrEstado: step.vlrEstado,
                vlrFinal: step.vlrFinal,
                vlrLucro: step.vlrLucro
              })
            }   
            
            if(GLOBAL_INDEX <= 1 || GLOBAL_INDEX > 1) GLOBAL_INDEX++
          })
        }else {
          const OPTION_SELECTED = step.options.find(d => d.selected === true)

          if(OPTION_SELECTED &&  OPTION_SELECTED.id > 0) {

            console.log(`${step.name}/${OPTION_SELECTED.label} - ${OPTION_SELECTED.id}`)
            STEPS_BY_PAYLOAD.ambientes.push({
              id: step.id,
              ambiente_id: step.idd,
              amb_tam_id: OPTION_SELECTED.id,
              ambiente_tamanho: {
                ambiente_id: step.idd
              },
              item: GLOBAL_INDEX,
              metragem: OPTION_SELECTED.metragem,
              selected: true,
              type: step.type as TStepTargets,
              vlrAcabamento: step.vlrAcabamento,
              vlrEstado: step.vlrEstado,
              vlrFinal: step.vlrFinal,
              vlrLucro: step.vlrLucro
            })
          }
          
          GLOBAL_INDEX++
        }
      })

      if(actions.setInterior && typeof actions.setInterior === 'function') actions.setInterior(STEPS_BY_PAYLOAD)
    } catch (error) {
      console.table(error)
    }
  }

  // console.log(`ENVIRONMENTS_DATA`,ENVIRONMENTS_DATA)
  // console.table(STEPS_MOUNTED)

  return (
    <>
      <Partials.Navigation
        showNavigation={showNavigation} 
        actualStepType={actualStepType} 
        stepsMounted={STEPS_MOUNTED} 
        onActualStepType={setActualStepType} 
      />

      <span
        data-selected={stepSelected && true}
        className='
          hidden
          font-medium
          text-[#707070]
          leading-[26px]
          data-[selected=false]:inline-block
        '
      >
        Nenhum passo selecionado
      </span>

      <Partials.Main 
        onPerQuantitySelected={setPerQuantitySelected}
        onSelectOptionByQuantity={setSelectOptionByQuantity}
        onStepQuantity={setStepQuantity}
        onStepQuantityPlus={setStepQuantityPlus}
        onStepQuantitySubstract={setStepQuantitySubstract}        
        onOptionSelected={setOptionSelected}
        perQuantitySelected={perQuantitySelected}
        showOptionPerQuantityREF={showOptionPerQuantityREF}
        stepSelected={stepSelected}                
      />

      {
        !STEPS_MOUNTED || STEPS_MOUNTED.length <= 0 && (
          <MockButton />
        )
      }

      <Partials.Footer
        stepSelected={stepSelected} 
        steps_mounted={STEPS_MOUNTED.length}
        steps_mounted_base={STEPS_MOUNTED}
        actualStepTypeIndex={actualStepTypeIndex}
        onActualStepType={setActualStepType}
        onFinished={handleOnFinished}
        statuses={status}
        step_type={stepSelected?.type as TStepTargets}        
      />
    </>      
  )
}