// Pages
import { Batches } from './Batches';
import { Compare } from './Compare';
import { Project } from './Project';
import { Projects } from './Projects';
import { RealEstateProject } from './RealEstateProject';
import { Study } from './Study';

export const RealEstateProduct = {
  Projects: (): JSX.Element => <Projects />,
  Project: (): JSX.Element => <Project />,
  RealEstateProject: (): JSX.Element => <RealEstateProject />,
  Batches: (): JSX.Element => <Batches.Table />,
  Batche: (): JSX.Element => <Batches.Batche />,
  Proposal: (): JSX.Element => <Batches.Proposal />,
  Compare: (): JSX.Element => <Compare />,
  Study: (): JSX.Element => <Study />,
};
